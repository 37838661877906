<div class="fixed z-10 w-full top-0 left-0 bg-secondary-content-bright mt-16 shadow-lg lg:hidden" [@slideInOut]="isCardburgerOpen" >
  <div class="flex flex-col text-center p-8 items-center gap-8 grow">
    <ng-container *ngTemplateOutlet="menuItems"></ng-container>
    <ng-container *ngTemplateOutlet="trialCta"></ng-container>
  </div>
</div>

<nav class="sticky z-20 top-0 left-0 w-full bg-secondary-content-bright shadow-lg">
  <div class="relative container mx-auto flex flex-row p-4 lg:pt-8 lg:pb-8 h-full ">
    <div>
      <app-logo class="h-10 lg:h-12 fill-primary-content-bright"></app-logo>
    </div>
    <div class="hidden lg:flex items-center gap-8 grow">
      <div class="flex grow justify-center">
        <ng-container *ngTemplateOutlet="menuItems"></ng-container>
      </div>
      <ng-container *ngTemplateOutlet="trialCta"></ng-container>
    </div>
    <div class="flex lg:hidden grow items-center justify-end">
      <button class="relative justify-center w-9" (click)="toggleCardburger()">
        <img class="w-6" src="./assets/cardburger.svg" />
        <img class="absolute top-0 -left-1.5 w-9" src="./assets/cardburger-close.svg" [@cardburger]="isCardburgerOpen" />
      </button>
    </div>
  </div>
</nav>

<ng-template #menuItems>
  <ul class="flex flex-col lg:flex-row gap-8 text-primary-content-bright text-lg">
  </ul>
</ng-template>

<ng-template #trialCta>
  <a href="/#contact">
  <app-button [style]="2" class="">Chiedi informazioni</app-button>
</a>
</ng-template>