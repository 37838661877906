
<div class="container mx-auto p-4 mt-8 mb-8 flex flex-col lg:flex-row gap-8 items-center">
  <div *ngIf="!isCopyLeft" class="basis-1/2">
      <div class="w-full h-full">
        <img src={{image}} class="rounded-2xl"/>
      </div>
  </div>
  <div class="basis-1/2">
    <app-detail-copy
    [title]=title
    [description]=description
    [ctaText]=ctaText
    [ctaLink]=ctaLink
    ></app-detail-copy>
  </div>
  <div *ngIf="isCopyLeft" class="basis-1/2">
    <div class="w-full h-full">
      <img src={{image}} class="rounded-2xl"/>
    </div>
  </div>
</div>
