<div class="flex flex-col gap-4 text-center items-center justify-center">

  
  <a href="/#contact"><app-button [style]="0">Chiedi informazioni</app-button></a>
  <div class="text-sm text-gray-500">
    <p *ngIf="includesEssentialTagline">Prova il piano Essential gratis per 30 giorni, successivamente €
      9,99/mese.</p>
    <p *ngIf="includesOrderDisclaimer">Ordini di carte fisiche non inclusi nella prova gratuita.</p>
  </div>

</div>