<div class="container mx-auto p-4 mt-8 mb-4">
  <h2 class="text-4xl text-primary-content-bright font-brand font-bold mb-8 text-center">Sì, ma quanto mi costa?</h2>
  <p class="text-lg items-center align-middle text-center">LetsCard si adatta alle tue esigenze.<br/>Scegli il piano che più fa per te, provalo gratuitamente per 30 giorni senza impegno e decidi.</p>
  
  <div class="flex flex-row gap-4 mt-8 mb-8 uppercase font-bold justify-center items-center">
    <p class="text-end {{isMonthlyPayment ? 'text-secondary-content-bright' : 'text-primary-content-bright'}}">
      Pagamento annuale</p>
    <app-switch (switchEmitter)="updateSwitchValue($event)" [isOn]="isMonthlyPayment"></app-switch>
    <p class="{{isMonthlyPayment ? 'text-primary-content-bright' : 'text-secondary-content-bright'}}">Pagamento mensile</p>
  </div>
  
  <div class="flex flex-col lg:flex-row gap-8 mt-8 mb-8">
    <app-plan-tab
    class="basis-1/3"
    title="Essential"
    description="Gestisci carte e pacchetti semplicemente. Stampa le carte da solo in base alle tue necessità."
    [yearlyPrice]=9
    [monthlyPrice]=12
    bgColor="bg-slate-200"
    [isMonthlyPayment]="isMonthlyPayment"
    ></app-plan-tab>

    <app-plan-tab
    class="basis-1/3"
    title="Premium"
    description="Gestisci carte e pacchetti semplicemente. Stampa carte plastificate di alta qualità."
    [yearlyPrice]=12
    [monthlyPrice]=15
    bgColor="bg-red-300"
    [isMonthlyPayment]="isMonthlyPayment"
    ></app-plan-tab>
    
    <app-plan-tab
    class="basis-1/3"
    title="Superior"
    description="Gestisci carte e pacchetti semplicemente. Stampa carte di materiali pregiati. Supporto prioritario."
    descriptionColor="text-white"
    [yearlyPrice]=35
    [monthlyPrice]=49
    bgColor="bg-gray-800"
    titleColor="text-slate-100"
    [isMonthlyPayment]="isMonthlyPayment"
    ></app-plan-tab>
  </div>
</div>