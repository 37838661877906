<h1 class="my-4">Verifica la tua email</h1>
<div [ngSwitch]="state">
  <app-message-box *ngSwitchCase="interfaceState.loading" [severity]="messageSeverity.alert" [size]="messageSize.large">
    Caricamento in corso...
  </app-message-box>

  <app-message-box *ngSwitchCase="interfaceState.error" [severity]="messageSeverity.error" [size]="messageSize.large">
    Si è verificato un errore durante la verifica dell'indirizzo email. Riprova.
  </app-message-box>

  <app-message-box *ngSwitchCase="interfaceState.success" [severity]="messageSeverity.error" [size]="messageSize.large">
    L'indirizzo email è stato verificato con successo.
  </app-message-box>
</div>