import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export enum ActionMode {
  resetPassword = "resetPassword",
  verifyEmail = "verifyEmail",
  undefined = "undefined"
}

export interface AccountData {
  mode: ActionMode;
  actionCode: string;
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements AccountData {
  mode: ActionMode;
  actionCode: string;
  email: string = "";
  actionMode = ActionMode;
  
  constructor(activatedRoute: ActivatedRoute) {
    const modeQuery = activatedRoute.snapshot.queryParams['mode'];
    this.mode = Object.values(ActionMode).find(value => value === modeQuery) ?? ActionMode.undefined;
    this.actionCode = activatedRoute.snapshot.queryParams['oobCode'];
  }

}
