
<div class="flex flex-col {{textColor}} {{bgColor}} rounded-3xl text-center">

  <div class="aspect-square w-full rounded-t-3xl {{image}} bg-cover bg-center">
  </div>
  <div class="flex flex-col p-8 gap-6">
    <h4 class="font-bold text-2xl font-brand">
      {{name}}
    </h4>
    <p>{{description}}</p>
    <p class="font-brand">
      A partire da<br/>
      <span class="text-4xl">&euro; {{startingPrice | number: '1.2-2'}}</span>/carta
    </p>
    <p>Acquistabile con i piani<br/><app-chip bgColor="bg-primary-content-bright">Premium</app-chip> e <app-chip bgColor="bg-slate-500">Superior</app-chip></p>
  </div>
</div>