import { Component, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent {

  isMonthlyPayment: boolean = false;
  
  updateSwitchValue(value: boolean) {
    this.isMonthlyPayment = value;
  }
}
