import { Component, OnInit, enableProdMode } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'letscard-website';

  ngOnInit(): void {
    if(environment.production) {
      enableProdMode();
    }
  }
}
