<h1 class="font-brand text-primary-brand font-bold text-4xl mt-4 mb-4">Reimposta la password</h1>
<div [ngSwitch]="state">
  <div *ngSwitchCase="interfaceState.entry" class="flex flex-col gap-4">
    <p>
      Reimposta la password per l'utente <span class="font-bold">{{email}}</span>
    </p>
    <form [formGroup]="form" class="flex flex-col gap-8 w-full">
      <input type="password" autofocus formControlName="newPassword" spellcheck="false" class="password-textfield">
      <div [ngSwitch]="strength">
        <app-message-box *ngSwitchCase="strengthState.empty" [severity]="messageSeverity.disabled"
          [size]="messageSize.small">
          Minimo 8 caratteri, una maiuscola, un numero e un carattere speciale.
        </app-message-box>

        <app-message-box *ngSwitchCase="strengthState.notEnough" [severity]="messageSeverity.alert"
          [size]="messageSize.small">
          Minimo 8 caratteri, una maiuscola, un numero e un carattere speciale.
        </app-message-box>

        <app-message-box *ngSwitchCase="strengthState.enough" [severity]="messageSeverity.success"
          [size]="messageSize.small" text="">
          Password sicura.
        </app-message-box>
      </div>
      <p>Ripeti la password</p>
      <input type="password" autofocus formControlName="repeatNewPassword" spellcheck="false"
        class="password-textfield">

      <app-message-box *ngIf="arePasswordsMismatching()" [severity]="messageSeverity.error" [size]="messageSize.small">
      Le password non coincidono.
      </app-message-box>

      <app-button class="" (click)="confirmPasswordReset()">Conferma</app-button>
    </form>

  </div>

  <app-message-box *ngSwitchCase="interfaceState.loading" [severity]="messageSeverity.alert" [size]="messageSize.large">
    Caricamento in corso...
  </app-message-box>

  <app-message-box *ngSwitchCase="interfaceState.error" [severity]="messageSeverity.error" [size]="messageSize.large">
    Si è verificato un errore durante il reset della password. Riprova.
  </app-message-box>

  <app-message-box *ngSwitchCase="interfaceState.success" [severity]="messageSeverity.success" [size]="messageSize.large">
    La password è stata modificata correttamente.
  </app-message-box>
</div>