import { Component, Input } from '@angular/core';
import { TridetailModel } from './tridetail/tridetail.component';

@Component({
  selector: 'app-tristep',
  templateUrl: './tristep.component.html',
  styleUrls: ['./tristep.component.css']
})
export class TristepComponent {

  @Input() title: string = 'E come funziona?';
  @Input() tridetails: TridetailModel[] = [];
}
