import { Component } from '@angular/core';
import { Links } from 'src/app/links';

@Component({
  selector: 'app-waiting-list',
  templateUrl: './waiting-list.component.html',
  styleUrls: ['./waiting-list.component.css']
})
export class WaitingListComponent {

  inviteForm: string = Links.inviteForm;
  contactForm: string = Links.contactForm;
}
