<button type="{{ submits ? 'submit' : 'button' }}" [disabled]="disabled" *ngIf="style === buttonStyle.primary"
  class="w-fit bg-primary-content-bright font-brand text-2xl font-bold text-white items-center rounded-xl p-4 pl-8 pr-8 text-center">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>
<button type="{{ submits ? 'submit' : 'button' }}" [disabled]="disabled" *ngIf="style === buttonStyle.secondary"
  class="mt-8 mb-8 text-center text-xl font-bold text-primary-content-bright bg-secondary-content-bright p-4 font-brand rounded-xl">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>
<button type="{{ submits ? 'submit' : 'button' }}" [disabled]="disabled" *ngIf="style === buttonStyle.tertiary"
  class="bg-primary-content-bright font-brand text-xl font-bold text-white items-center rounded-xl p-3 text-center">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>


<ng-template #content>
  <ng-content></ng-content>
</ng-template>