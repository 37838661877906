<div class="container mx-auto p-4 lg:p-8 my-4 w-full leading-normal">
  <h1 class="mb-16 text-center">{{componentTitle}}</h1>
  <div class="overflow-x-scroll overflow-y-visible">
    <div class="flex flex-col lg:flex-row gap-8 lg:gap-14">
      <div *ngFor="let cardDetail of cardDetails" class="basis-1/3">
        <div
          class="flex flex-col min-h-40 lg:min-h-56 gap-4 lg:aspect-card-horizontal z-10 rounded-3xl p-4 text-primary-content-bright bg-secondary-content-bright leading-normal">
          <h2>{{cardDetail?.title}}</h2>
          <p class="text-black rounded-3xl text-sm lg:text-xl leading-normal">{{cardDetail?.description}}</p>
        </div>
      </div>
    </div>
  </div>
</div>