import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TridetailModel } from './tristep/tridetail/tridetail.component';
import { CardDetailModel } from './tricard/card-detail/card-detail.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  isProduction = environment.production;

  tricard1: CardDetailModel[] = [
    {
      title: "È semplice e veloce.",
      description: "LetsCard semplifica la vendita e l'utilizzo delle carte regalo. Inquadra la carta. Attivala o riscattala. Fatto!",
      colorSchemeInverted: false
    },
    {
      title: "Ti aiuta con le tue carte regalo...",
      description: "LetsCard ti aiuta a monitorare gli importi residui e le scadenze delle carte. Dì addio a carta e penna o fogli Excel.",
      colorSchemeInverted: false
    },
    {
      title: "...e aiuta i tuoi clienti.",
      description: "Chi riceve una LetsCard può controllare comodamente, in ogni momento, l'importo residuo della carta e la sua scadenza.",
      colorSchemeInverted: false
    },
  ];

  tricard2: CardDetailModel[] = [
    {
      title: "Personalizza la grafica.",
      description: "Carica la grafica e personalizza la tua carta. A tutto il resto ci pensa LetsCard.",
      colorSchemeInverted: true
    },
    {
      title: "Richiedi carte dall'app.",
      description: "Stai finendo le carte? Puoi ordinarne di nuove in qualsiasi momento dall'app.",
      colorSchemeInverted: true
    },
    {
      title: "La carta su misura per te.",
      description: "Puoi scegliere l'importo, impostare una scadenza e associare un nome a ciascuna carta.",
      colorSchemeInverted: true
    },
  ];

  tristep1: TridetailModel[] = [
    {
      title: "Carica il design per la tua carta o pacchetto.",
      description: "Carica il tuo design in alta qualità, in formato immagine o PDF.",
      image: "bg-tristep-01"
    },
    {
      title: "Associa un taglio alla carta (facoltativo).",
      description: "Se vuoi, associa un taglio alla carta. Puoi farlo anche all’attivazione.",
      image: "bg-tristep-02"
    },
    {
      title: "Paga, ordina e attendi l’arrivo delle carte.",
      description: "Le carte arriveranno presso la tua attività nel giro di qualche giorno.",
      image: "bg-tristep-03"
    },
  ];
  tristep2: TridetailModel[] = [
    {
      title: "Per vendere una carta, attivala.",
      description: "Scansiona la carta, scegli il taglio, associa una scadenza e il nome, se necessario.",
      image: "bg-tristep-04"
    },
    {
      title: "Riscatta la carta ogni volta che l’utente la usa.",
      description: "Scansiona la carta e scala dall’importo rimanente.",
      image: "bg-tristep-05"
    },
    {
      title: "Fatto!",
      description: "Quando l’importo di una carta è esaurito non potrà più essere utilizzata.",
      image: "bg-tristep-06"
    }
  ];
}
