<a href="/">
  <svg class="{{class}}" viewBox="0 0 203 55" xmlns="http://www.w3.org/2000/svg">
    <path d="M50.3499 13.6826H55.2578V36.2388H66.7712V40.571H50.3499V13.6826Z"/>
    <path d="M72.9013 39.6296C71.3718 38.7136 70.1827 37.4782 69.3297 35.9108C68.4767 34.3477 68.0481 32.6165 68.0481 30.713C68.0481 28.7885 68.4767 27.0489 69.3297 25.4984C70.1827 23.9479 71.3508 22.7251 72.8299 21.8343C74.309 20.9435 75.9646 20.498 77.7924 20.498C79.7631 20.498 81.4733 20.9266 82.9188 21.7796C84.3643 22.6326 85.4652 23.7966 86.2216 25.2631C86.9779 26.7296 87.3603 28.3431 87.3603 30.0995C87.3603 30.9189 87.2763 31.5786 87.1082 32.0829H72.9602C73.103 33.5283 73.6577 34.6797 74.6199 35.5453C75.5822 36.4109 76.7713 36.8437 78.1916 36.8437C79.2505 36.8437 80.1581 36.621 80.9145 36.1756C81.6708 35.7302 82.2801 35.1125 82.7381 34.3183L86.5619 36.1966C85.961 37.6421 84.9021 38.8018 83.3852 39.68C81.8683 40.5582 80.1245 40.9952 78.1538 40.9952C76.1789 41.0036 74.4309 40.5456 72.9013 39.6296ZM82.3053 28.4776C82.1835 27.2758 81.7171 26.2926 80.8977 25.5362C80.0783 24.7798 79.0446 24.3975 77.7924 24.3975C76.5907 24.3975 75.5654 24.7546 74.725 25.4606C73.8804 26.1707 73.3299 27.175 73.0652 28.4734H82.3053V28.4776Z"/>
    <path d="M93.9576 38.9657C92.7432 37.7513 92.1339 36.0369 92.1339 33.8225V25.158H88.7429V20.9351H89.1043C90.0666 20.9351 90.8103 20.6829 91.3398 20.1787C91.8692 19.6745 92.1339 18.9391 92.1339 17.9769V16.46H96.8611V20.9351H101.374V25.158H96.8611V33.5662C96.8611 35.6125 97.9662 36.6336 100.181 36.6336C100.567 36.6336 100.987 36.5958 101.445 36.5243V40.6044C100.677 40.7263 99.8949 40.7851 99.1008 40.7851C96.8863 40.7851 95.1719 40.18 93.9576 38.9657Z"/>
    <path d="M106.665 39.5584C105.16 38.6213 104.097 37.3313 103.471 35.6967L106.971 33.8941C107.501 34.953 108.203 35.7766 109.081 36.3649C109.959 36.9531 110.926 37.2473 111.984 37.2473C112.9 37.2473 113.615 37.0624 114.132 36.6884C114.648 36.3144 114.909 35.806 114.909 35.1547C114.909 34.6 114.69 34.1378 114.258 33.7638C113.825 33.3899 113.258 33.1461 112.56 33.0243L109.35 32.4822C107.69 32.0494 106.408 31.3099 105.505 30.2636C104.602 29.2173 104.152 27.9819 104.152 26.5659C104.152 25.3893 104.463 24.3346 105.089 23.406C105.715 22.4816 106.585 21.763 107.707 21.2588C108.825 20.7546 110.094 20.5024 111.514 20.5024C113.316 20.5024 114.905 20.931 116.279 21.784C117.649 22.637 118.649 23.822 119.275 25.3389L115.775 27.1415C115.388 26.3011 114.8 25.6246 114.006 25.1204C113.211 24.6162 112.333 24.364 111.371 24.364C110.577 24.364 109.946 24.5447 109.476 24.9061C109.005 25.2675 108.774 25.7381 108.774 26.3138C108.774 27.3726 109.568 28.0828 111.157 28.4442L114.296 29.0198C115.955 29.4778 117.241 30.2216 118.157 31.2595C119.073 32.2932 119.527 33.5201 119.527 34.9404C119.527 36.1211 119.208 37.1674 118.569 38.0793C117.93 38.9953 117.035 39.7012 115.88 40.2097C114.724 40.7139 113.388 40.966 111.875 40.966C109.909 40.966 108.169 40.4996 106.665 39.5584Z"/>
    <path d="M128.54 39.1966C126.493 37.9949 124.888 36.3351 123.72 34.2173C122.552 32.0995 121.968 29.7296 121.968 27.1076C121.968 24.4856 122.552 22.1157 123.72 19.9979C124.888 17.8801 126.485 16.2245 128.519 15.0353C130.553 13.8462 132.843 13.2495 135.393 13.2495C137.246 13.2495 138.944 13.5689 140.482 14.2076C142.02 14.8463 143.327 15.6993 144.398 16.7708C145.47 17.8423 146.243 19.0272 146.726 20.3256L142.322 22.3846C141.793 20.9895 140.915 19.8634 139.688 19.0104C138.461 18.1574 137.028 17.7288 135.393 17.7288C133.733 17.7288 132.254 18.1196 130.956 18.9012C129.658 19.6827 128.653 20.7836 127.943 22.2039C127.233 23.6242 126.88 25.2587 126.88 27.1118C126.88 28.9649 127.233 30.6078 127.943 32.0365C128.653 33.4694 129.658 34.5745 130.956 35.3561C132.254 36.1376 133.733 36.5284 135.393 36.5284C137.028 36.5284 138.461 36.0998 139.688 35.2468C140.915 34.3938 141.793 33.2677 142.322 31.8726L146.726 33.9316C146.243 35.23 145.47 36.4149 144.398 37.4864C143.327 38.5579 142.02 39.4109 140.482 40.0496C138.944 40.6883 137.246 41.0077 135.393 41.0077C132.868 41.0035 130.582 40.4026 128.54 39.1966Z"/>
    <path d="M150.861 39.4867C149.646 38.4783 149.037 37.1042 149.037 35.373C149.037 33.7132 149.6 32.3434 150.735 31.2593C151.865 30.1752 153.563 29.4566 155.823 29.0953L161.668 28.1582V27.5069C161.668 26.6413 161.336 25.9312 160.677 25.3765C160.013 24.8219 159.143 24.5445 158.059 24.5445C157.05 24.5445 156.151 24.8135 155.37 25.3555C154.588 25.8976 154.016 26.6119 153.655 27.5027L149.794 25.6244C150.369 24.0865 151.428 22.8469 152.97 21.9057C154.508 20.9687 156.265 20.498 158.239 20.498C159.828 20.498 161.24 20.7922 162.479 21.3805C163.719 21.9687 164.681 22.8007 165.366 23.8722C166.051 24.9437 166.395 26.1539 166.395 27.4985V40.5624H161.92V38.4698C160.378 40.1548 158.336 40.9952 155.786 40.9952C153.718 41.0036 152.075 40.4994 150.861 39.4867ZM154.706 36.7975C155.21 37.1967 155.861 37.3941 156.655 37.3941C158.122 37.3941 159.328 36.9319 160.265 36.0033C161.202 35.0789 161.672 33.9275 161.672 32.5577V31.7635L156.727 32.5955C155.79 32.7888 155.092 33.0955 154.634 33.5157C154.176 33.9359 153.949 34.4948 153.949 35.1923C153.945 35.8646 154.197 36.4025 154.706 36.7975Z"/>
    <path d="M170.479 20.935H174.917V23.6789C175.396 22.62 176.085 21.8636 176.976 21.4056C177.866 20.9476 178.946 20.7207 180.224 20.7207H181.379V24.9059H179.682C178.333 24.9059 177.253 25.3261 176.434 26.1707C175.614 27.0152 175.207 28.1666 175.207 29.6373V40.5708H170.479V20.935Z"/>
    <path d="M187.178 39.6505C185.674 38.747 184.489 37.5159 183.623 35.9527C182.758 34.3896 182.325 32.6584 182.325 30.7549C182.325 28.8556 182.758 27.1202 183.623 25.5571C184.489 23.9939 185.682 22.7586 187.195 21.8593C188.712 20.9559 190.384 20.5063 192.212 20.5063C193.464 20.5063 194.607 20.7164 195.641 21.1366C196.674 21.5568 197.54 22.1535 198.238 22.9224V13.2495H202.965V40.5707H198.49V38.4067C197.767 39.2513 196.868 39.8942 195.784 40.3396C194.7 40.785 193.523 41.0077 192.246 41.0077C190.372 41.0035 188.682 40.5497 187.178 39.6505ZM187.934 33.8013C188.405 34.7047 189.061 35.4065 189.901 35.9107C190.741 36.4149 191.695 36.6671 192.754 36.6671C193.813 36.6671 194.758 36.4149 195.586 35.9107C196.418 35.4065 197.065 34.7005 197.536 33.8013C198.007 32.8979 198.238 31.881 198.238 30.7507C198.238 29.6204 198.002 28.6035 197.536 27.7001C197.065 26.7966 196.41 26.0949 195.569 25.5907C194.725 25.0864 193.788 24.8343 192.754 24.8343C191.695 24.8343 190.746 25.0949 189.901 25.6117C189.056 26.1285 188.405 26.8387 187.934 27.7421C187.464 28.6455 187.233 29.6498 187.233 30.7549C187.233 31.8852 187.464 32.9021 187.934 33.8013Z"/>
    <path opacity="0.5" d="M6.56705 37.2032V19.7944H5.13838C2.50795 19.7944 0.373354 21.929 0.373354 24.5595V49.3469C0.373354 51.9773 2.50795 54.1119 5.13838 54.1119H17.2484C19.8789 54.1119 22.0135 51.9773 22.0135 49.3469V48.4476L12.6557 45.6617C9.2395 44.6448 6.56705 40.9303 6.56705 37.2032Z"/>
    <path opacity="0.75" d="M22.0133 37.3756L21.8368 37.2454C19.0677 35.2452 16.9793 31.1357 16.9793 27.6859V9.99983L13.3782 8.92834C12.9916 8.81488 12.6219 8.76025 12.2647 8.76025C10.4368 8.76025 9.08801 10.2141 9.08801 12.416V37.2033C9.08801 39.8338 11.0083 42.5398 13.374 43.2458L24.2739 46.4897C24.3621 46.5149 24.4462 46.5275 24.5302 46.5485C24.8244 46.6158 25.1101 46.662 25.3874 46.662C27.2153 46.662 28.5641 45.2081 28.5641 43.0063V42.107L22.0133 37.3756Z"/>
    <path d="M32.9973 7.87761L23.3075 0.885539C22.6142 0.385506 21.9629 0.145996 21.4041 0.145996C20.2653 0.145996 19.4964 1.13346 19.4964 2.89828V27.6857C19.4964 29.7446 20.5469 32.2532 22.0091 33.976C22.4041 34.4382 22.8285 34.8458 23.2697 35.1736C23.2823 35.182 23.2949 35.1946 23.3075 35.203L24.5303 36.0854L32.9973 42.195C33.6906 42.6951 34.3419 42.9346 34.9008 42.9346C36.0395 42.9346 36.8085 41.9471 36.8085 40.1823V15.3949C36.8085 12.7645 35.1025 9.39872 32.9973 7.87761Z"/>
  </svg>  
</a>