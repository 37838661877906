import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TridetailModel } from './tristep/tridetail/tridetail.component';
import { CardDetailModel } from './tricard/card-detail/card-detail.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  isProduction = environment.production;

  tricard1: CardDetailModel[] = [
    {
      title: "Crea le tue carte regalo.",
      description: "Personalizzale con le grafiche che desideri.",
      colorSchemeInverted: false
    },
    {
      title: "Scansionale.",
      description: "Bastano pochi secondi per attivare o riscattare le carte che hai venduto.",
      colorSchemeInverted: false
    },
    {
      title: "Gestiscile.",
      description: "Dimentica il caos delle carte gestite a mano: LetsCard tiene traccia di importi residui e scadenze.",
      colorSchemeInverted: false
    },
  ];

  tricard2: CardDetailModel[] = [
    {
      title: "Stai finendo le carte?",
      description: "LetsCard ti avvisa quando è il momento di ordinare nuove carte.",
      colorSchemeInverted: true
    },
    {
      title: "A ognuno la sua carta.",
      description: "Vendi ciascuna carta scegliendo importo, scadenza e destinatario.",
      colorSchemeInverted: true
    },
    {
      title: "E chi riceve le carte?",
      description: "Può controllare in ogni momento l'importo residuo e la data di scadenza.",
      colorSchemeInverted: true
    },
  ];

  tristep1: TridetailModel[] = [
    {
      title: "Carica il design per la tua carta o pacchetto.",
      description: "Carica il tuo design in alta qualità, in formato immagine o PDF.",
      image: "bg-tristep-01"
    },
    {
      title: "Associa un taglio alla carta (facoltativo).",
      description: "Se vuoi, associa un taglio alla carta. Puoi farlo anche all’attivazione.",
      image: "bg-tristep-02"
    },
    {
      title: "Paga, ordina e attendi l’arrivo delle carte.",
      description: "Le carte arriveranno presso la tua attività nel giro di qualche giorno.",
      image: "bg-tristep-03"
    },
  ];
  tristep2: TridetailModel[] = [
    {
      title: "Per vendere una carta, attivala.",
      description: "Scansiona la carta, scegli il taglio, associa una scadenza e il nome, se necessario.",
      image: "bg-tristep-04"
    },
    {
      title: "Riscatta la carta ogni volta che l’utente la usa.",
      description: "Scansiona la carta e scala dall’importo rimanente.",
      image: "bg-tristep-05"
    },
    {
      title: "Fatto!",
      description: "Quando l’importo di una carta è esaurito non potrà più essere utilizzata.",
      image: "bg-tristep-06"
    }
  ];
}
