export const environment = {
  production: false,
  apiBase: 'https://api-dev.letscard.app',
  firebaseConfig: {
    apiKey: "AIzaSyCNV59bh3Sf5rDvmadqlTcz28xT4JJpmb0",
    authDomain: "letscard-dev.firebaseapp.com",
    projectId: "letscard-dev",
    storageBucket: "letscard-dev.appspot.com",
    messagingSenderId: "250405368207",
    appId: "1:250405368207:web:c908fd73f275d278945bfa",
    measurementId: "G-ZZXSSLXRS4"
  }
};
