<div class="flex flex-col gap-6 h-full {{bgColor}} {{descriptionColor}} rounded-3xl p-8 text-center">
  <h3 class="text-3xl font-bold font-brand {{titleColor}}">{{title}}</h3>
  <p class="text-xl">{{description}}</p>
  <p class="font-brand font-3xl">
    <span class="text-6xl">
      &euro; {{isMonthlyPayment ? monthlyPrice : yearlyPrice}} 
    </span><span class="-ml-2">/mese</span></p>
  <p class="text-sm flex-1">
    <span *ngIf="!isMonthlyPayment">Pagamento annuale in un'unica soluzione di € {{yearlyPrice * 12}}.</span>
    Costo delle carte fisiche non incluso.
  </p>
  <app-cta-trial [includesOrderDisclaimer]=false ></app-cta-trial>
</div>