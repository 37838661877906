import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-feature-detail',
  templateUrl: './feature-detail.component.html',
  styleUrls: ['./feature-detail.component.css']
})
export class FeatureDetailComponent {

  @Input() title: string = "Vendi carte con una doppia anima, fisica e digitale.";
  @Input() description: string = "Puoi personalizzare la grafica, la finitura, l'importo e la scadenza di ciascun credito. I tuoi clienti potranno controllare in tempo reale l'importo residuo scansionando la carta.";
  @Input() ctaText: string = "Scopri le finiture";
  @Input() ctaLink: string = '';
  @Input() image: string = './assets/card-plastic.jpg';
  @Input() isCopyLeft: boolean = false;
}
