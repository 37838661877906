import { Component, Input } from '@angular/core';

export enum ButtonStyle {
  primary = 0,
  secondary = 1,
  tertiary = 2
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent {
  @Input() style: ButtonStyle = ButtonStyle.primary;
  @Input() disabled: boolean = false;
  @Input() submits: boolean = true;
  public buttonStyle = ButtonStyle;
}
