import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Firestore, addDoc, collection } from '@angular/fire/firestore';

enum ContactFormState {
  Input = 0,
  Sending = 1,
  Sent = 2,
  Error = 3
}
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent {

  contactForm: FormGroup;
  state: ContactFormState = ContactFormState.Input;
  contactFormState = ContactFormState;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly firestore: Firestore
  ) {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      company: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: [''],
      privacy: [false, Validators.requiredTrue]
    });
  }

  async onSubmit() {
    this.state = ContactFormState.Sending;
    if (this.contactForm.valid) {
      const docData = this.contactForm.value;
      docData.timestamp = new Date();
      const contactCollection = collection(this.firestore, 'contact_requests');
      const docRef = await addDoc(contactCollection, docData);
      this.state = ContactFormState.Sent;
    }
  }

}
