<div class="container mx-auto p-4 mt-4 mb-4">
  <h2 class="text-3xl text-primary-content-bright font-brand font-bold mb-16">Finiture disponibili</h2>
  <div class="flex flex-col md:flex-row gap-8">
    <app-finish
    class="basis-1/3"
    name="LetsCard Paper"
    description="Stampa le tue carte su carta riciclabile da 300 g/m2."
    [startingPrice]=0.50
    image="bg-card-paper"
    bgColor="bg-gray-100"
    textColor="text-gray-700"
    ></app-finish>
    <app-finish
    class="basis-1/3"
    name="LetsCard Plastic"
    description="Stampa le tue carte su una base di poliuretano bio vegano riciclato."
    [startingPrice]=1.00
    image="bg-card-plastic"
    bgColor="bg-cyan-200"
    textColor="text-cyan-900"
    ></app-finish>
    <app-finish
    class="basis-1/3"
    name="LetsCard Metal"
    description="Rendi le tue carte esclusive. Carte in metallo o titanio."
    [startingPrice]=5.00
    image="bg-card-metal"
    bgColor="bg-gray-900"
    textColor="text-gray-100"
    ></app-finish>
  </div>
  <p class="m-8 text-center">
    Tutte le carte sono in formato standard ISO 7810 (85.6 &times; 53.98 mm, 3.37 &times; 2.12 in)<br/>
    Prezzi mostrati per un ordine di almeno 250 carte per finitura. Ordine minimo di 50 carte per finitura.
  </p>
</div>
