import { Component, Input, OnInit } from '@angular/core';
import { Auth, applyActionCode } from '@angular/fire/auth';
import { AccountData, ActionMode } from '../account.component';
import { MessageSeverity, MessageSize } from 'src/app/message-box/message-box.component';

enum InterfaceState {
  success,
  error,
  loading
}

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit, AccountData {

  @Input() mode: ActionMode = ActionMode.verifyEmail;
  @Input() actionCode: string = "";
  state: InterfaceState = InterfaceState.loading;

  interfaceState = InterfaceState;
  messageSeverity = MessageSeverity;
  messageSize = MessageSize;
  
  constructor(private auth: Auth) {}

  ngOnInit(): void {
  applyActionCode(this.auth, this.actionCode).then((resp: any) => {
    this.state = InterfaceState.success;
  }).catch((error: any) => {
    this.state = InterfaceState.error;
  });
  }
}
