<footer class="w-full bg-gray-200">
  <div class="relative container mx-auto flex flex-col lg:flex-row gap-4 pt-8 pb-16 px-4  text-gray-700">
    <div class="flex basis-1/4 flex-col gap-2">
      <app-logo class="h-12 fill-gray-700"></app-logo>
      
        è un servizio ideato e fornito da
        <a href="https://www.letvar.io" target="_blank">
          <img src="./assets/lv-logo.svg" class="h-4 inline align-baseline" alt="letvar logo" />
        </a>
    </div>
    <div class="basis-1/4 mt-3">
      <p class="font-bold pb-2">Seguici su</p>
      <div class="flex flex-row gap-4">
        <a href="https://www.facebook.com/letscardapp" target="_blank">
          <img src="./assets/social/fb.svg" class="h-8" alt="Facebook" />
        </a>
        <a href="https://www.instagram.com/letscard.app" target="_blank">
          <img src="./assets/social/ig.svg" class="h-8" alt="Instagram" />
        </a>
        <a href="https://x.com/letscardapp" target="_blank">
          <img src="./assets/social/x.svg" class="h-8" alt="X (aka Twitter)" />
        </a>

      </div>
      <!--<ul class="flex flex-col gap-2 text-md">
        <li><a href="{{downloadApp}}" target="_blank">Scarica l'app</a></li>
      </ul>-->
    </div>
    <div class="basis-1/4 mt-3">
      <p class="font-bold pb-2">Informazioni su LetsCard</p>
      <ul class="flex flex-col gap-2">
        <li><a href="/#contact">Chiedi informazioni</a></li>
        <li><a href="/tnc">Termini e Condizioni</a></li>
        <li><a href="/privacy" target="_blank">Privacy Policy</a></li>
        <li><a href="/cookies">Cookie Policy</a></li>
        <li><a href="https://www.letvar.io/it/impressum" target="_blank">Impressum</a></li>
      </ul>
    </div>
    <div class="basis-1/4 mt-3 flex flex-col gap-2">
      <p>
        <a href="https://www.letvar.io" target="_blank" class="font-bold">letvar srl</a><br />
        COE/VAT ID SM30688<br />
        Via Ventotto Luglio 99<br />
        47893 Borgo Maggiore (SMR)
      </p>
      <p>&copy; 2024 letvar srl. Tutti i diritti riservati.</p>
    </div>
    <!--
    <div class="basis-1/3 mt-3 mb-8">
      <p class="font-bold font-brand">Scarica le nostre app</p>
      <a href="{{downloadApp}}" target="_blank">
        <div class="flex flex-row gap-4 w-36 pt-4 pb-4">
          <img src="./assets/appstore.svg" />
          <img src="./assets/googleplay.svg" />
        </div>
      </a>
      <div class="text-xs text-gray-400">
        <p>iOS e App Store sono marchi registrati di Apple Inc.
          <br/>Google Play e il logo di Google Play sono marchi di Google LLC.</p>
      </div>
    </div>
    -->
  </div>
</footer>