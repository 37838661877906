import { Component } from '@angular/core';
import { Links } from '../links';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  inviteForm: string = Links.inviteForm;
  downloadApp: string = Links.downloadApp;
  contactForm: string = Links.contactForm;
}
