import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, Input } from '@angular/core';

export interface CardDetailModel {
  title: string;
  description: string;
  colorSchemeInverted: boolean;
}

@Component({
  selector: 'app-card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.css'],
  animations: [
    trigger('scrollUp', [
      state('false', style({transform: 'translate(1rem, 42%)', offset: 0})),
      transition('false => true', [
        animate('2s ease-in-out', keyframes([
          style({transform: 'translate(1rem, 42%)', offset: 0}),
          style({transform: 'translate(0, 42%)', offset: 0.40}),
          style({transform: 'translate(0, 42%)', offset: 0.60}),
          style({transform: 'translate(0, 0)', offset: 1.0}),
        ])) 
      ])
    ]),

    trigger('scrollDown', [
      state('false', style({transform: 'translate(-1rem, -42%)', offset: 0})),
      transition('false => true', [
        animate('2s ease-in-out', keyframes([
          style({transform: 'translate(-1rem, -42%)', offset: 0}),
          style({transform: 'translate(0, -42%)', offset: 0.40}),
          style({transform: 'translate(0, -42%)', offset: 0.60}),
          style({transform: 'translate(0, 0)', offset: 1.0}),
        ])) 
      ])
    ])
  ]
})

export class CardDetailComponent {

  @Input() model?: CardDetailModel;
  @Input() isAnimationStarted: boolean = false;
}
