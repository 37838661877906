import { Component, Inject, Input } from '@angular/core';
import { Links } from '../links';

@Component({
  selector: 'app-cta-trial',
  templateUrl: './cta-trial.component.html',
  styleUrls: ['./cta-trial.component.css']
})
export class CtaTrialComponent {

  @Input() includesEssentialTagline: boolean = false;
  @Input() includesOrderDisclaimer: boolean = false;
  inviteForm: string = Links.inviteForm;
}
