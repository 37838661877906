<header class="bg-header-mobile md:bg-header bg-contain md:bg-cover bg-top bg-no-repeat md:bg-left min-h-[75vh] bg-[#df9379]">
  <div class="container mx-auto flex flex-col lg:flex-row p-4 min-h-[75vh] justify-center items-center">
    <div class="h-60 lg:h-auto md:basis-7/12"></div>
    <div class="flex flex-col sm:backdrop-blur-sm md:backdrop-blur-none gap-12 basis-5/12 mt-16 bg-white rounded-3xl bg-opacity-90 md:bg-opacity-80 p-8 lg:bg-transparent lg:p-0">
      <h1>
        Le tue carte regalo a portata di app.
      </h1>
      <h3>LetsCard è il servizio per gestire le carte regalo della tua attività.</h3>
  
        <app-cta-trial class="lg:mb-16 self-start"></app-cta-trial>
    </div>
  </div>
</header>