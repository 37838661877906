import { Component, Input } from '@angular/core';

export enum MessageSeverity {
  disabled,
  alert,
  error,
  success
}

export enum MessageSize {
  small,
  medium,
  large,
  default
}

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent {

  @Input() text: string = "";
  @Input() size: MessageSize = MessageSize.default;
  @Input() severity: MessageSeverity = MessageSeverity.disabled;

  messageSeverity = MessageSeverity;
  messageSize = MessageSize;

  tailwindSize(): string {
    switch(this.size) {
      case MessageSize.small:
        return "text-sm";
      case MessageSize.medium:
        return "text-md";
      case MessageSize.large:
        return "text-2xl";
      case MessageSize.default:
        return "";
    }
  }

  symbolSize(): string {
    switch(this.size) {
      case MessageSize.small:
        return "icon-small";
      case MessageSize.medium:
        return "icon-medium";
      case MessageSize.large:
        return "icon-large";
      case MessageSize.default:
        return "";
    }
  }

  textColor(): string {
    switch(this.severity) {
      case MessageSeverity.disabled:
        return "text-gray-400";
      case MessageSeverity.alert:
        return "text-orange-500";
      case MessageSeverity.error:
        return "text-red-700";
      case MessageSeverity.success:
        return "text-green-700";
    }
  }

  icon(): string {
    switch(this.severity) {
      case MessageSeverity.disabled:
        return "radio_button_unchecked";
      case MessageSeverity.alert:
        return "error";
      case MessageSeverity.error:
        return "cancel";
      case MessageSeverity.success:
        return "task_alt";
    }
  }


}
