import { Component, ElementRef, HostListener, Input, OnInit, Output } from '@angular/core';
import { CardDetailModel } from './card-detail/card-detail.component';

@Component({
  selector: 'app-tricard',
  templateUrl: './tricard.component.html',
  styleUrls: ['./tricard.component.css']
})
export class TricardComponent {

  @Input() title: string = 'E come funziona?';
  @Input() cardDetails: CardDetailModel[] = [];
  @Input() tricardId: string = "";

  @Output() isTricardShown = false;


  private observer?: IntersectionObserver;

  constructor(private element: ElementRef) {}

  ngAfterViewInit() {
    let options = {
      threshold: 1.0
    }
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isTricardShown = true;
        }
      });
    }, options);

    this.observer.observe(this.element.nativeElement);
  }

  ngOnDestroy() {
    this.observer?.disconnect();
  }

}
