import { Component, ElementRef, HostListener, Input, OnInit, Output } from '@angular/core';
import { CardDetailModel } from './card-detail/card-detail.component';

@Component({
  selector: 'app-tricard',
  templateUrl: './tricard.component.html'
})
export class TricardComponent {

  @Input() componentTitle: string = 'E come funziona?';
  @Input() cardDetails: CardDetailModel[] = [];
  @Input() tricardId: string = "";

  constructor(private element: ElementRef) {}

}
