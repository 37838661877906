<section class="container mx-auto p-4 lg:p-8 mt-4 mb-4 w-full text-center">
  <p id="contact" class="absolute -mt-40"></p>
  <h1 class="mb-8">
    Vuoi saperne di più su LetsCard?
  </h1>
  <h3>
    Se vuoi ricevere maggiori informazioni su LetsCard o se vuoi provarlo gratuitamente compila il form sottostante senza impegno.
  </h3>
  <h3>
    Ti ricontatteremo al più presto!
  </h3>

  <div *ngIf="state == contactFormState.Sent" class="h-52 align-middle text-2xl bg-green-200 font-bold rounded-xl flex items-center justify-center mt-8 p-8">
    Grazie per averci contattato!<br/>Abbiamo ricevuto la tua richiesta e ti risponderemo al più presto!
  </div>
  <div *ngIf="state != contactFormState.Sent">
  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="flex flex-col w-full mt-12 gap-8 text-lg">
    <div class="form-two-cols">
      <div class="textfield">
        <label for="name">Nome e cognome*</label>
        <input class="input" type="text" formControlName="name" placeholder="Giovanni Mela">
      </div>
      <div class="textfield">
        <label for="company">Nome dell'attività*</label>
        <input class="input" type="text" formControlName="company" placeholder="Negozio o azienda">
      </div>
    </div>
    <div class="form-two-cols">
      <div class="textfield">
        <label for="role">Telefono*</label>
        <input class="input" type="phone" formControlName="phone" placeholder="+39 333 1234567">
      </div>
      <div class="textfield">
        <label for="email">Email*</label>
        <input class="input" type="email" formControlName="email" placeholder="mail@esempio.com">
      </div>
    </div>
    <div class="textfield">
      <label for="message">Messaggio</label>
      <textarea class="textarea" formControlName="message" placeholder="Scrivi qui il tuo messaggio (facoltativo)"></textarea>
      
    </div>
    <div class="flex flex-col  items-center justify-center lg:flex-row gap-8">
      <div class="flex flex-row grow lg:basis-2/3 min-h-full gap-4 justify-start items-start text-left">
        <input type="checkbox" formControlName="privacy" required
          class="w-6 h-6 aspect-square rounded-xl border-brand border-solid border-2 ">
          <div class="flex flex-col gap-2">

            <label for="privacy">
              Dichiaro di aver preso visione dell'<a href="/privacy"
                target="_blank">informativa privacy</a>.*</label>
                <!--<p class="text-xs text-neutral-600">
                  Questo sito è protetto da reCAPTCHA e si applicano le <a href="https://policies.google.com/privacy" target="_blank">norme sulla privacy</a> e i <a href="https://policies.google.com/terms" target="_blank">termini di servizio</a> di Google.
                </p>-->
          </div>
      </div>
      <div class="{{ contactForm.valid ? 'opacity-100' : 'opacity-50' }}">
        <div *ngIf="state == contactFormState.Input">
          <app-button [submits]="false" [disabled]="!contactForm.valid" (click)="onSubmit()">Invia richiesta</app-button>
        </div>
        <div *ngIf="state == contactFormState.Sending" class="h-16 align-middle font-bold flex items-center justify-center">
          Invio in corso...
        </div>
      </div>
    </div>
    <!--<button type="submit" [disabled]="!contactForm.valid">Send</button>-->
  </form>
  </div>
</section>