import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, Input } from '@angular/core';

export interface CardDetailModel {
  title: string;
  description: string;
  colorSchemeInverted: boolean;
}

@Component({
  selector: 'app-card-detail',
  templateUrl: './card-detail.component.html'
})

export class CardDetailComponent {

  @Input() model?: CardDetailModel;
}
