import { Component, Input } from '@angular/core';
import { CardDetailModel } from '../tricard/card-detail/card-detail.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-card-feature',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card-feature.component.html'
})
export class CardFeatureComponent {
  
  @Input() componentTitle: string = 'E come funziona?';
  @Input() cardDetails: CardDetailModel[] = [];

}
