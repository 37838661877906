<div class="container mx-auto p-4 pr-0 lg:p-8 mt-4 mb-4 w-full text-center">
  <h2 class="text-4xl lg:text-5xl text-primary-content-bright font-brand font-bold mb-8 leading-normal">
    Vuoi saperne di più su LetsCard?
  </h2>
  <div class="flex flex-col lg:flex-row gap-8 pt-8 pb-8 text-xl lg:divide-x-2">
    <div class="flex flex-col gap-8 basis-1/2">
      <p class="text-center leading-normal">Al momento accettiamo un numero limitato di nuovi partner sulla piattaforma.<br/>
        Per entrare in lista d'attesa, compila il seguente form senza impegno.<br/>
        Ti contatteremo appena saranno disponibili nuovi posti!<br/>
      </p>
      <a href="{{inviteForm}}" target="_blank">
      <app-button [style]="0" class="">Entra in lista d'attesa</app-button>
    </a>
    </div>
    <div class="flex flex-col gap-8 basis-1/2">
      <p class="leading-normal">Vuoi avere maggiori informazioni su LetsCard?<br/>Compila il form di contatto, riceverai tutte le informazioni senza impegno.</p>
      
      <a href="{{contactForm}}" target="_blank">
        <app-button [style]="1">Chiedi informazioni</app-button>
      </a>
    </div>
  
    
  </div>
</div>