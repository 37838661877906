import { Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Links } from '../links';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  animations: [
    trigger('slideInOut', [
      state('true', style({
        transform: 'translateY(0%)',
        opacity: 1
      })),
      state('false', style({
        transform: 'translateY(-100%)',
        opacity: 0
      })),
      transition('0 <=> 1', [
        animate('0.3s ease-in-out')
      ])
    ]),
    trigger('cardburger', [
      state('true', style({
        transform: 'scaleY(100%)',
        opacity: 1
      })),
      state('false', style({
        transform: 'scaleY(0%)',
        opacity: 0
      })),
      transition('0 <=> 1', [
        animate('0.3s ease-in-out')
      ])
    ])
  ]
})
export class MenuComponent {

 inviteForm: string = Links.inviteForm;
 isCardburgerOpen = false;

 toggleCardburger() {
  this.isCardburgerOpen = !this.isCardburgerOpen;
 }
}
