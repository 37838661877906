import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AccountComponent } from './account/account.component';
import { RedirectGuard } from './redirect.guard';

const routes: Routes = [
  { 
    path: 'account-recovery',
    component: AccountComponent,
    pathMatch: 'full'
  },
  {
    path: 'privacy',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.iubenda.com/privacy-policy/88472595'
    },
    pathMatch: 'full'
  },
  {
    path: 'cookies',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.iubenda.com/privacy-policy/88472595/cookie-policy',
    },
    pathMatch: 'full'
  },
  {
    path: 'tnc',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: '/assets/docs/letscard-tnc-latest.pdf'
    },
    pathMatch: 'full'
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
