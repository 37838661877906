<app-header></app-header>

<app-tricard componentTitle="Cosa puoi fare con LetsCard?" [cardDetails]=tricard1 tricardId="tricard1"></app-tricard>
<div class="bg-cards-table bg-center bg-cover h-[80vh] w-full"></div>
<app-card-feature componentTitle="...e non solo!" [cardDetails]=tricard2></app-card-feature>
<div class="bg-phones bg-cover bg-center h-screen w-full"></div>
<app-contact-form></app-contact-form>
<!--
<app-waiting-list></app-waiting-list>
-->
<!--
<app-feature-detail
title="Vendi carte con una doppia anima, fisica e digitale"
description="Puoi personalizzare la grafica, la finitura, l’importo e la scadenza di ciascun credito. I tuoi clienti potranno controllare in tempo reale l’importo residuo scansionando la carta."
ctaText="Scopri le finiture"
image="./assets/giftcard-shop.jpg"
[isCopyLeft]="true"></app-feature-detail>
<app-feature-detail
title="Gestisci e controlla facilmente i crediti della tua attività."
description="Tieni traccia di pacchetti e regali venduti e riscattati dagli utenti nel tempo. Controlla il credito verso i clienti e le scadenze."
ctaText="Scopri tutte le funzionalità"
image="./assets/data-cmp.jpg"></app-feature-detail>

<app-tristep title="Come funziona?" [tridetails]=tristep1></app-tristep>
<app-tristep title="Come uso le carte?" [tridetails]=tristep2></app-tristep>
<app-pricing></app-pricing>

<app-finishes></app-finishes>

<app-feature-detail
title="E questo è solo l'inizio..." 
description="Lavoriamo costantemente per migliorare ed aggiungere nuove funzionalità a LetsCard. Qualche esempio? L’app per utenti LetsCard, con la quale potranno conservare le carte ed acquistarle direttamente dall’app, senza alcuno sforzo da parte tua."
ctaText="Tienimi aggiornato"
image="./assets/laptop.jpg"></app-feature-detail>-->