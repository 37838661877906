import { Component, Input } from '@angular/core';

export interface TridetailModel {
  title: string;
  description: string;
  image: string;
}

@Component({
  selector: 'app-tridetail',
  templateUrl: './tridetail.component.html',
  styleUrls: ['./tridetail.component.css']
})
export class TridetailComponent {

  @Input() model?: TridetailModel;

}
