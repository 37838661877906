import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-plan-tab',
  templateUrl: './plan-tab.component.html',
  styleUrls: ['./plan-tab.component.css'],
  animations: [
  ]
})
export class PlanTabComponent {

  @Input() title: string = 'Premium';
  @Input() titleColor: string = 'text-primary-content-bright';
  @Input() description: string = 'Gestisci carte e pacchetti semplicemente. Stampa carte plastificate di alta qualità.';
  @Input() descriptionColor: string = 'text-black';
  @Input() bgColor: string = 'bg-secondary-content-bright';
  @Input() yearlyPrice: number = 12.0;
  @Input() monthlyPrice: number = 15.0;
  @Input() isMonthlyPayment: boolean = false;

}
