import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Auth, verifyPasswordResetCode, confirmPasswordReset } from '@angular/fire/auth';
import { AccountData, ActionMode } from '../account.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageSeverity, MessageSize } from 'src/app/message-box/message-box.component';

enum InterfaceState {
  entry,
  success,
  error,
  loading
}

enum StrengthState {
  empty,
  notEnough,
  enough
}

export interface PasswordFieldsPair {
  newPassword: string;
  repeatNewPassword: string;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements AccountData, OnInit {

  private passwordStrengthRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!\-@#\$%\^&\*]).{8,}$');

  @Input() mode: ActionMode = ActionMode.resetPassword;
  @Input() actionCode: string = "";
  @Input() newPassword: string = "";
  @Input() repeatNewPassword: string = "";
  state: InterfaceState = InterfaceState.loading;
  email: string = "";
  error: string = "";
  form: FormGroup;
  strength: StrengthState = StrengthState.empty;
  
  interfaceState = InterfaceState;
  strengthState = StrengthState;
  messageSeverity = MessageSeverity;
  messageSize = MessageSize;
  
  constructor(private auth: Auth, private formBuilder: FormBuilder) {
    this.auth = auth;
    this.form = this.formBuilder.group({
      newPassword: ['', Validators.required],
      repeatNewPassword: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.form?.controls['newPassword'].valueChanges.subscribe(this.onFieldsChange.bind(this));
    this.form?.controls['repeatNewPassword'].valueChanges.subscribe(this.onFieldsChange.bind(this));

    verifyPasswordResetCode(this.auth, this.actionCode).then((email: any) => {
      this.email = email;
      this.state = InterfaceState.entry;
    }).catch((error: any) => {
      this.error = error;
      this.state = InterfaceState.error;
    });
  }

  onFieldsChange() {
    this.newPassword = this.form.controls['newPassword'].value;
    this.repeatNewPassword = this.form.controls['repeatNewPassword'].value;

    if(this.newPassword == "") {
      this.strength = StrengthState.empty;
    } else {
      if(this.passwordStrengthRegex.test(this.newPassword)) {
        this.strength = StrengthState.enough;
      } else {
        this.strength = StrengthState.notEnough;
      }
    }
  }

  confirmPasswordReset() {

    if(this.arePasswordsMismatching()) {
      alert("Le password non coincidono.")
      return;
    }

    if(!this.passwordStrengthRegex.test(this.newPassword)) {
      alert("La password non rispetta i criteri di sicurezza: minimo 8 caratteri, una maiuscola, un numero e un carattere speciale");
      return;
    }

    this.state = InterfaceState.loading;

    confirmPasswordReset(this.auth, this.actionCode, this.newPassword).then((resp: any) => {
      this.state = InterfaceState.success;
    }).catch((error: any) => {
      this.state = InterfaceState.error;
    });
  }

  arePasswordsMismatching(): boolean {
    return this.newPassword !== this.repeatNewPassword;
  }
}
