import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})
export class FinishComponent {

  @Input() name: string = 'LetsCard Papier';
  @Input() description: string = 'Stampa le tue carte su carta riciclabile da 300 g/m2.';
  @Input() startingPrice: number = 0.50;
  @Input() plans: string[] = ['Premium', 'Superior'];
  @Input() image: string = 'bg-card-paper';
  @Input() bgColor: string = 'bg-gray-300';
  @Input() textColor: string = 'text-black';
}
