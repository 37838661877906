import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css'],
  animations: [
    trigger('switchIsOn', [
      state('true', style({
        'left': '2.25rem'
      })),
      state('false', style({
        'left': '0.25rem'
      })),
      transition('0 <=> 1', [
        animate('0.3s ease-in-out')
      ])
    ])
  ]
})
export class SwitchComponent {

  @Input() isOn: boolean = false;
  @Output() switchEmitter = new EventEmitter<boolean>();

  toggle() {
    this.isOn = !this.isOn;
    this.switchEmitter.emit(this.isOn);
  }
}
