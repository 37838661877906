import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './home/header/header.component';
import { PricingComponent } from './home/pricing/pricing.component';
import { FeatureDetailComponent } from './home/feature-detail/feature-detail.component';
import { TristepComponent } from './home/tristep/tristep.component';
import { TridetailComponent } from './home/tristep/tridetail/tridetail.component';
import { DetailCopyComponent } from './home/feature-detail/detail-copy/detail-copy.component';
import { ButtonComponent } from './button/button.component';
import { CtaTrialComponent } from './cta-trial/cta-trial.component';
import { PlanTabComponent } from './home/pricing/plan-tab/plan-tab.component';
import { SwitchComponent } from './home/pricing/switch/switch.component';
import { LogoComponent } from './logo/logo.component';
import { FinishesComponent } from './home/finishes/finishes.component';
import { FinishComponent } from './home/finishes/finish/finish.component';
import { ChipComponent } from './chip/chip.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TricardComponent } from './home/tricard/tricard.component';
import { CardDetailComponent } from './home/tricard/card-detail/card-detail.component';
import { WaitingListComponent } from './home/waiting-list/waiting-list.component';
import { AccountComponent } from './account/account.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { VerifyEmailComponent } from './account/verify-email/verify-email.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { ContactFormComponent } from './home/contact-form/contact-form.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { RedirectGuard } from './redirect.guard';
import { CardFeatureComponent } from "./home/card-feature/card-feature.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    NavigationComponent,
    MenuComponent,
    HeaderComponent,
    PricingComponent,
    FeatureDetailComponent,
    TristepComponent,
    TridetailComponent,
    DetailCopyComponent,
    ButtonComponent,
    CtaTrialComponent,
    PlanTabComponent,
    SwitchComponent,
    LogoComponent,
    FinishesComponent,
    FinishComponent,
    ChipComponent,
    TricardComponent,
    CardDetailComponent,
    WaitingListComponent,
    AccountComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    MessageBoxComponent,
    ContactFormComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideAuth(() => getAuth()),
    CardFeatureComponent
],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LdfZtEpAAAAAAi6xEoq0ADbBLr08ZmftZW0Bjed'
    },
    RedirectGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
